import React from "react";
import {
  Box,
  Button,
  Heading,
  HStack,
  Container,
  Flex,
  Text,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  useToast,
} from "@chakra-ui/react";
import { LockIcon } from "../../components/Icons";
import Sidebar from "./sidebar";
import { useSelector } from "react-redux";
import { PasswordInput } from "../../components/Inputs";
import { PrimaryButton } from "../../components/Buttons";
import { changePassword } from "../../utils/calls/profile.calls";
import { useLocation } from "react-router-dom";

const MyDetails = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setisLoading] = React.useState(false);
  const [oldPassword, setoldPassword] = React.useState("");
  const [newPassword, setnewPassword] = React.useState("");

  const { user } = useSelector((state) => state.user);

  const handleChangePassword = () => {
    setisLoading(true);
    changePassword(user.id, oldPassword, newPassword)
      .then((response) => {
        setisLoading(false);
        onClose();
        toast({
          title: `${response.data.message}`,
          status: "success",
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Something went wrong, try again",
          status: "error",
          isClosable: true,
        });
        setisLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Container maxWidth="7xl" marginTop={20}>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <Box width={["100%", "100%", "30%"]}>
            <Sidebar />
          </Box>

          <Box width={["100%", "100%", "63%"]} marginBottom={24}>
            <Box maxWidth="450px">
              <Heading color="#3C4859" fontSize="24px" fontWeight="600">
                My Details
              </Heading>
              <Text
                fontSize="14px"
                fontWeight="300"
                color="#3C4859"
                marginTop={2}
              >
                Change your account details
              </Text>
              {/* change password */}
              <Button
                marginTop={5}
                background="#2D7FF9"
                height="44px"
                rounded={4}
                color="white"
                width="200px"
                boxShadow="0px 4px 7px rgba(0, 0, 0, 0.05)"
                _hover={{}}
                onClick={onOpen}
              >
                <HStack>
                  <LockIcon />
                  <Text>Change Password</Text>
                </HStack>
              </Button>
              <Modal
                onClose={onClose}
                isOpen={isOpen}
                isCentered
                motionPreset="slideInBottom"
              >
                <ModalOverlay />
                <ModalContent
                  borderRadius={0}
                  p={["34px 5%", "34px 5%", "51px 105px", "51px 105px"]}
                  maxWidth={["100%", "90%", "658px", "658px"]}
                  m={["auto 0 0", "initial"]}
                >
                  <Text
                    textAlign={["left", "center"]}
                    color="#4E5457"
                    fontSize={[22, 32]}
                    fontWeight={400}
                    lineHeight="32px"
                    mb={["25px", "30px"]}
                  >
                    Change Password
                  </Text>
                  <Text
                    textAlign={["left", "center"]}
                    color="#4E5457"
                    fontSize={16}
                    fontWeight={400}
                    mb={["25px", "30px"]}
                  >
                    Enter your new password for Syndicate account
                  </Text>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleChangePassword();
                    }}
                  >
                    <Stack
                      spacing="21px"
                      marginBottom="29px"
                      maxW="340px"
                      marginX="auto"
                    >
                      <PasswordInput
                        label="Old password"
                        handleInput={(payload) => setoldPassword(payload)}
                      />
                      <PasswordInput
                        label="New password"
                        handleInput={(payload) => setnewPassword(payload)}
                      />
                      <PrimaryButton
                        type="submit"
                        disabled={!newPassword || !oldPassword || isLoading}
                        label="Change Password"
                      />
                    </Stack>
                  </form>
                </ModalContent>
              </Modal>
              {/* change password */}

              <HStack justifyContent="space-between" marginTop={10}>
                <Box
                  alignItems="flex-start"
                  border="1px solid #C4C4C4"
                  rounded={4}
                  width="48%"
                  padding="10px 15px"
                >
                  <Text color="#3C4859" fontWeight="600">
                    First Name
                  </Text>
                  <Text color="#979797" fontWeight="400">
                    {user.first_name}
                  </Text>
                </Box>

                <Box
                  alignItems="flex-start"
                  border="1px solid #C4C4C4"
                  rounded={4}
                  width="48%"
                  padding="10px 15px"
                >
                  <Text color="#3C4859" fontWeight="600">
                    Last Name
                  </Text>
                  <Text color="#979797" fontWeight="400">
                    {user.last_name}
                  </Text>
                </Box>
              </HStack>

              <Box
                marginTop={8}
                alignItems="flex-start"
                border="1px solid #C4C4C4"
                rounded={4}
                padding="10px 15px"
              >
                <Text color="#3C4859" fontWeight="600">
                  Email address
                </Text>
                <Text color="#979797" fontWeight="400">
                  {user.email}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default MyDetails;
