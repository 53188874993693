import { Box, HStack, Text, VStack } from "@chakra-ui/layout";
import React from "react";
import { ShowFilterOptionsIcon } from "../Icons";

const FilterModalMobile = ({ children, show, close }) => {
  return (
    <>
      {show && (
        <VStack
          display={["flex", "flex", "flex", "none"]}
          justifyContent="space-between"
          height="100%"
          width="100%"
          background="rgba(0,0,0,0.5)"
          position="fixed"
          top={0}
          left={0}
          zIndex={2}
        >
          <Box
            background="transparent"
            width="100%"
            height="100%"
            onClick={close}
          ></Box>
          <Box background="white" position="relative" width="100%">
            <HStack
              borderBottom="0.5px solid #ddd"
              padding={4}
              onClick={close}
              cursor="pointer"
            >
              <ShowFilterOptionsIcon />
              <Text fontWeight="600" fontSize={16} color="#3C4859">
                Filter
              </Text>
            </HStack>
            <Box paddingX={4} paddingY={10}>
              {children}
            </Box>
          </Box>
        </VStack>
      )}
    </>
  );
};

export default FilterModalMobile;
