import React from "react";
import {
  Box,
  Heading,
  Text,
  Flex,
  Button,
  Container,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { GreenTickIcon } from "../../components/Icons";
import Sidebar from "./sidebar";
import { useSelector } from "react-redux";
import {
  getInvestorProfile,
  updateInvestorProfile,
} from "../../utils/calls/profile.calls";

const AccountPreference = () => {
  const toast = useToast();
  const { user } = useSelector((state) => state.user);
  const [amountToInvest, setamountToInvest] = React.useState([]);
  const [interestedAreas, setinterestedAreas] = React.useState([]);
  const [stages, setstages] = React.useState([]);

  React.useEffect(() => {
    getInvestorProfile(user.id)
      .then((response) => {
        setamountToInvest(response.data.amountToInvest); // amounts to invest
        setinterestedAreas(response.data.interestedAreas);
        setstages(response.data.stages);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updatePreference = () => {
    updateInvestorProfile(user.id, amountToInvest, interestedAreas, stages)
      .then((response) => {
        toast({
          title: `${response.data.message}`,
          status: "success",
          isClosable: true,
        });
      })
      .catch((err) => console.log(err));
  };

  const handleAmountCheck = (payload) => {
    //  find index
    const amountIndex = amountToInvest.findIndex(
      (amount) => amount.name === payload
    );
    let amountsCopy = [...amountToInvest];
    amountsCopy.map((amount) => {
      return (amount.selected = false);
    });
    amountsCopy[amountIndex] = {
      ...amountsCopy[amountIndex],
      selected: true,
    };
    setamountToInvest(amountsCopy);
  };

  const handleInterestsCheck = (payload) => {
    //  find index
    const interestIndex = interestedAreas.findIndex(
      (interest) => interest.name === payload
    );
    let interestsCopy = [...interestedAreas];
    interestsCopy[interestIndex] = {
      ...interestsCopy[interestIndex],
      selected: !interestsCopy[interestIndex].selected,
    };
    setinterestedAreas(interestsCopy);
  };

  const handleStagesCheck = (payload) => {
    //  find index
    const stageIndex = stages.findIndex((stage) => stage.name === payload);
    let stagesCopy = [...stages];
    stagesCopy.map((stage) => {
      return (stage.selected = false);
    });
    stagesCopy[stageIndex] = {
      ...stagesCopy[stageIndex],
      selected: true,
    };
    setstages(stagesCopy);
  };

  return (
    <>
      <Container maxWidth="7xl" marginTop={20}>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <Box width={["100%", "100%", "30%"]}>
            <Sidebar />
          </Box>
          <Box width={["100%", "100%", "63%"]}>
            <Box maxWidth="450px">
              <Heading color="#3C4859" fontSize="24px" fontWeight="600">
                Account Preferences
              </Heading>
              <Text
                fontSize="14px"
                fontWeight="300"
                color="#3C4859"
                marginTop={2}
              >
                Change your account preferences
              </Text>
              {/* amount */}
              <Box
                border="1px solid #E5E5E5"
                padding="15px 15px 0 15px"
                rounded={4}
                marginTop={8}
              >
                <Text fontWeight="500" marginBottom={2}>
                  How much are you willing to invest?
                </Text>
                <Flex flexWrap="wrap">
                  {amountToInvest.map((amount, index) => {
                    return (
                      <RadioCard
                        name={amount.name}
                        selected={amount.selected}
                        key={index}
                        handleCheck={(payload) => handleAmountCheck(payload)}
                      />
                    );
                  })}
                </Flex>
              </Box>

              {/* interests */}
              <Box
                border="1px solid #E5E5E5"
                padding="15px 15px 0 15px"
                rounded={4}
                marginTop={8}
              >
                <Box marginBottom={2}>
                  <Text fontWeight="500">
                    What areas are you interested in investing in?
                  </Text>
                  <Text fontWeight="300" fontSize={12}>
                    Select as many options as you like
                  </Text>
                </Box>
                <Flex flexWrap="wrap">
                  {interestedAreas.map((interest, index) => {
                    return (
                      <RadioCard
                        name={interest.name}
                        selected={interest.selected}
                        key={index}
                        handleCheck={(payload) => handleInterestsCheck(payload)}
                      />
                    );
                  })}
                </Flex>
              </Box>

              {/* stages */}
              <Box
                border="1px solid #E5E5E5"
                padding="15px 15px 0 15px"
                rounded={4}
                marginTop={8}
              >
                <Text fontWeight="500" marginBottom={2}>
                  What stage of startups are you willing to invest in?
                </Text>
                <Flex flexWrap="wrap">
                  {stages.map((stage, index) => {
                    return (
                      <RadioCard
                        name={stage.name}
                        selected={stage.selected}
                        key={index}
                        handleCheck={(payload) => handleStagesCheck(payload)}
                      />
                    );
                  })}
                </Flex>
              </Box>
              <Button
                marginTop={10}
                marginBottom={32}
                backgroundColor="#2D7FF9"
                color="white"
                height="64px"
                width="100%"
                rounded="0 10px 10px 10px"
                onClick={() => updatePreference()}
              >
                Save Changes
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AccountPreference;

const RadioCard = ({ selected, name, handleCheck }) => {
  return (
    <Box
      rounded={4}
      marginRight="12px"
      border={selected ? "1px solid #3BB273" : "1px solid #E1E1E1"}
      padding="9px 13px"
      marginBottom="15px"
      cursor="pointer"
      onClick={() => handleCheck(name)}
      fontWeight={selected ? "500" : "200"}
      color="#5A6163"
    >
      <HStack>
        <Text>{name}</Text>
        {selected && <GreenTickIcon />}
      </HStack>
    </Box>
  );
};
