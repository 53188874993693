import React, { useState } from "react";
import {
  Box,
  Text,
  Heading,
  Flex,
  HStack,
  Button,
} from "@chakra-ui/react";
import { DocIcon } from "../../../components/Icons";
import VideoPlayer from "./videoPlayer";

// using ES6 modules
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// using CommonJS modules
import "react-pdf/dist/umd/Page/AnnotationLayer.css";
import { Link } from "react-router-dom";

const Pitch = ({ startup, media }) => {
  const [videoUrl, setvideoUrl] = useState("");

  React.useEffect(() => {
    let video = media.find((item) => {
      return item.doc_type === "VIDEO_UPLOAD";
    });
    video
      ? setvideoUrl(video.doc_url)
      : setvideoUrl(
          "https://drive.google.com/file/d/1sYGfmVB3fKQMz1N8j-TDFV2wjQPsi7hf/preview"
        );
  }, [media]);

  const docName = (url, ext) => {
    const urlParts = url.replace("https://", "").split("/");
    return `${urlParts[0]}/${urlParts[1]}/${ext}`;
  };

  return (
    <Box paddingY={5}>
      {videoUrl ? (
        <VideoPlayer fileUrl={videoUrl} fileType="video/mp4" />
      ) : (
        "https://drive.google.com/file/d/1sYGfmVB3fKQMz1N8j-TDFV2wjQPsi7hf/preview"
      )}

      <Text marginTop={10} color="#4E5457" fontSize="16px" lineHeight="24px">
        {startup.pitch}
      </Text>
      <Box
        marginTop={10}
        border="1px solid #F1F1F1"
        padding={["10px", "22px 32px"]}
      >
        <Heading
          color="#3C4859"
          fontSize={20}
          fontWeight="600"
          marginBottom={8}
        >
          Company Documents
        </Heading>
        {media.length === 0 && (
          <Flex
            background="#F9F9F9"
            justifyContent="space-between"
            alignItems="center"
            padding={["10px", "20px"]}
            marginBottom={4}
          >
            No Documents available.
          </Flex>
        )}

        {media.map((item) => {
          if (item.doc_type !== "VIDEO_UPLOAD")
            return (
              <Flex
                background="#F9F9F9"
                justifyContent="space-between"
                alignItems="center"
                padding={["10px", "20px"]}
                marginBottom={4}
                key={`list-item-${item.id}`}
              >
                <HStack spacing={3}>
                  <Box display={["none", "block"]}>
                    <DocIcon />
                  </Box>
                  <Text fontSize={[14, 18]} color="#4E5457">
                    {item.doc_name}
                  </Text>
                </HStack>
                <Link
                  to={{
                    pathname: `/dashboard/startups/doc/${docName(
                      item.doc_url,
                      item.doc_ext
                    )}`,
                  }}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="ghost"
                    color="#2D7FF9"
                    fontWeight="600"
                    fontSize={[16, 18]}
                    cursor="pointer"
                  >
                    View
                  </Button>
                </Link>
              </Flex>
            );
          return null;
        })}
      </Box>
    </Box>
  );
};

export default Pitch;
