import api from "../api";
import axios from "axios";

let { VERIFICATION_API } = api;
let baseUrl = VERIFICATION_API;

const token = JSON.parse(localStorage.getItem("token"));

// retrieve investor profile
export const getInvestorProfile = async (userId) => {
  let response = await axios.get(`${baseUrl}/v1/investor/profile/${userId}`, {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

// update investor account preference
export const updateInvestorProfile = async (
  user_id,
  amountToInvest,
  interestedAreas,
  stages
) => {
  let response = await axios.put(
    `${baseUrl}/v1/investor/profile/update`,
    {
      user_id,
      amountToInvest,
      interestedAreas,
      stages,
    },
    {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

// change password
export const changePassword = async (id, old_password, new_password) => {
  let response = await axios.put(
    `${baseUrl}/v1/auth/changePassword/${id}`,
    {
      old_password,
      new_password,
    },
    {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};
