import React, { useState } from "react";
import numeral from "numeral";
import {
  Box,
  Flex,
  Text,
  Grid,
  Divider,
  Container,
  Heading,
  Circle,
  HStack,
  Avatar,
} from "@chakra-ui/react";
import { Document, Page, pdfjs } from "react-pdf";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { StartupPageCard } from "../../components/StartUpCard";
import { TextInput } from "../../components/Inputs";
import PaymentItemContent from "../../components/PaymentItemContent";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import { retrieveStartup } from "../../utils/calls/startups.calls";
import { Plus, Minus, AvatarArrow } from "../../components/Icons";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ItemDecorator = ({ no }) => (
  <HStack>
    <Circle
      fontSize={12}
      border='0.5px solid #C4C4C4'
      color='#3C4859'
      width='28px'
      height='28px'
      justifyContent='center'
      alignItems='center'
    >
      {no}
    </Circle>
    <Divider color='#C4C4C4' />
  </HStack>
);

const Invest = () => {
  numeral.defaultFormat("$0,0.00");

  const history = useHistory();
  const location = useLocation();
  const [numPages, setNumPages] = useState(null);
  const [startup, setstartup] = React.useState({});
  const [pageNumber] = useState(1);
  const [view, setView] = useState("invest");
  const [amount, setAmount] = useState("");
  const [charge, setCharge] = useState("");
  const [user, setUser] = useState("");
  const [focusState, setFocusState] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  React.useEffect(() => {
    if (amount.trim() === "") {
      setFocusState(false);
    } else {
      setFocusState(true);
    }
  }, [amount]);

  React.useEffect(() => {
    const id = location.pathname.split("/startups/invest/")[1];

    retrieveStartup(id)
      .then(response => {
        setstartup(response.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [location.pathname]);

  React.useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setUser(userData);
  }, []);

  return (
    <>
      <Box background='#FAFBFD' paddingY={["24px"]}>
        <Container height='100%' maxWidth='7xl' alignItems='center'>
          <NavLink to='/dashboard/startups'>
            <Flex alignItems='center' width='auto' cursor='pointer'>
              <ArrowBackIcon boxSize='16px' />{" "}
              <Heading
                fontWeight='500'
                color='#4E5457'
                fontSize={16}
                lineHeight='24px'
                ml='8px'
              >
                Back to Startups
              </Heading>
            </Flex>
          </NavLink>
        </Container>
      </Box>
      <Container
        maxW='7xl'
        paddingY={["24px", "24px", "40px"]}
        marginBottom='100px'
      >
        <Grid
          gridTemplateColumns={["1fr", "1fr", "1fr", "1fr 2fr"]}
          gap={[0, 0, 0, "50px"]}
        >
          <Box>
            <StartupPageCard
              startup={startup}
              view={view}
              setView={setView}
              link={
                <Text
                  color='#2D7FF9'
                  fontSize={[12, 12, 12, 16]}
                  cursor='pointer'
                  onClick={() =>
                    history.push(`/dashboard/startups/${startup.id}`)
                  }
                >
                  View{" "}
                  <Box as='span' display={["none", "none", "inline", "inline"]}>
                    Investment
                  </Box>{" "}
                  Deck
                </Text>
              }
            />
          </Box>
          <Box>
            {view === "details" && (
              <Flex
                border='0.25px solid #858585'
                borderRadius='4px'
                padding='14px'
                justifyContent='center'
              >
                <Box>
                  <Document
                    file='/sample.pdf'
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                  <p>
                    Page {pageNumber} of {numPages}
                  </p>
                </Box>
              </Flex>
            )}
            {view === "invest" && (
              <Box width={["100%", "100%", "100%", "65%"]}>
                <Text color='#3C4859' fontSize={[18, 18, 18, 32]} mb='40px'>
                  Invest in {startup.legal_name}
                </Text>
                <ItemDecorator no='1' />
                <Text color='#3C4859' fontSize={20} my='29px'>
                  Amount to invest
                </Text>

                <Box
                  background='#F9F9F9'
                  borderRadius='4px'
                  alignItems='center'
                  p={["6px 10px", "14px 25px", "14px 25px", "14px 25px"]}
                  m='8px 0 25px'
                  fontSize={[12, 14, 14, 14]}
                  flexWrap='wrap'
                >
                  <Text>
                    The minimium amount you can invest is
                    <Box as='span' fontWeight={600} color='#00BB8D'>
                      &nbsp;$5000
                    </Box>
                  </Text>
                </Box>

                <TextInput
                  label='How much do you want to invest?'
                  placeholder='0'
                  left='$'
                  type='number'
                  value={amount}
                  maxLength={21}
                  handleInput={value => {
                    setAmount(value);
                    setCharge(0.1 * +value);
                  }}
                />
                {/* THE TIMELINE BEGINS */}
                <HStack
                  justifyContent='space-evenly'
                  width='100%'
                  alignItems='end'
                >
                  <Flex
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='flex-start'
                  >
                    <Box
                      display={"block"}
                      height='50px'
                      borderWidth={1}
                      borderColor={"#E2E6E8"}
                    />
                    <Circle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      width='20px'
                      height='20px'
                      background={"#E2E6E8"}
                    >
                      {focusState ? <Plus /> : <Minus />}
                    </Circle>
                  </Flex>
                  <Box
                    width='75%'
                    style={{ position: "relative", bottom: "-15px" }}
                  >
                    <Text color='#858585' fontSize='14px' fontWeight={400}>
                      Transaction charge
                    </Text>
                    <Text color='#3C4859' fontSize='18px' fontWeight={400}>
                      {numeral(charge).format()}
                    </Text>
                  </Box>
                </HStack>

                <HStack
                  justifyContent='space-evenly'
                  width='100%'
                  alignItems='end'
                  style={{ position: "relative", left: "-3.5px" }}
                >
                  <Flex
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='flex-start'
                  >
                    <>
                      <Box
                        display={"block"}
                        height='50px'
                        borderWidth={1}
                        borderColor={"#E2E6E8"}
                      />
                      <Avatar
                        name={
                          focusState && `${user?.first_name} ${user?.last_name}`
                        }
                        width='40px'
                        height='40px'
                        background='#E2E6E8'
                        color='#3C4859'
                        fontWeight={600}
                        icon={
                          <AvatarArrow fontWeight={600} fontSize='1.5rem' />
                        }
                      />
                    </>
                  </Flex>
                  <Box
                    width='75%'
                    style={{ position: "relative", bottom: "-5px" }}
                  >
                    <Text color='#858585' fontSize='14px' fontWeight={400}>
                      You send
                    </Text>
                    <Text color='#3C4859' fontSize='18px' fontWeight={400}>
                      {numeral(charge + +amount).format()}
                    </Text>
                  </Box>
                </HStack>
                <HStack
                  justifyContent='space-evenly'
                  width='100%'
                  alignItems='end'
                >
                  <Flex
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='flex-start'
                  >
                    <>
                      <Box
                        display={"block"}
                        height='50px'
                        borderWidth={1}
                        borderColor={"#E2E6E8"}
                      />
                      <Circle width='12px' height='12px' />
                    </>
                  </Flex>
                  <Box width='75%'></Box>
                </HStack>
                {/* THE TIMELINE ENDs */}
                <Box mb='45px' style={{ position: "relative", top: "-12px" }}>
                  <TextInput
                    left=''
                    placeholder='0'
                    label='Total (incl. fees)'
                    value={numeral(+charge + +amount).format()}
                    borderColor={focusState && "#00BB8D"}
                    color={focusState && "#00BB8D"}
                    readOnly={true}
                  />
                </Box>

                <ItemDecorator no='2' />
                <Text color='#3C4859' fontSize={20} my='29px'>
                  Pay by bank transfer
                </Text>
                <PaymentItemContent
                  status='awaiting'
                  payment_tag='AWAITING_PAYMENT'
                  invest
                  amount={+amount + +charge}
                  startup_id={startup.id}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Container>
    </>
  );
};

export default Invest;
