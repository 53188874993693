import React from "react";
import {
  Box,
  Heading,
  HStack,
  Grid,
  Container,
  Skeleton,
  Button,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MenuDropOption } from "../../components/Options";
import { StartUpListItem } from "../../components/StartUpCard";
import { retrieveStartups } from "../../utils/calls/startups.calls";
import { ShowFilterOptionsIcon } from "../../components/Icons";
import { getCategories, getStages } from "../../utils/calls/options";
import OptionsModal from "../../components/MobileModals/OptionsModal";
import FilterModalMobile from "../../components/MobileModals/FilterModalMobile";

const Startups = () => {
  const [startups, setstartups] = React.useState([]);
  const [loading, setloading] = React.useState(true);

  const [showFilter, setshowFilter] = React.useState(false);
  const [showOptions, setshowOptions] = React.useState(false);
  const [optionsType, setoptionsType] = React.useState("");

  const [stages, setstages] = React.useState([]);
  const [selectedStage, setselectedStage] = React.useState("");
  const [categories, setcategories] = React.useState([]);
  const [selectedCategory, setselectedCategory] = React.useState("");
  const [periods, setperiods] = React.useState([]);
  const [selectedPeriod, setselectedPeriod] = React.useState("");

  React.useEffect(() => {
    // set periods
    setperiods([
      {
        id: 1,
        name: "New Deals",
      },
      {
        id: 2,
        name: "Previous Deals",
      },
    ]);
    setselectedPeriod("New Deals");
    getCategories()
      .then((response) => {
        setcategories(response.data);
        setselectedCategory("All Sectors");
      })
      .catch((err) => {
        console.log(err);
      });

    getStages()
      .then((response) => {
        setstages(response.data);
        setselectedStage("All Stages");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    setloading(true);
    setstartups([]);
    retrieveStartups(selectedPeriod, selectedCategory, selectedStage)
      .then((response) => {
        setstartups(response.data);
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedCategory, selectedStage, selectedPeriod]);
  return (
    <>
      <Box background="#FEFEFE">
        <Box borderBottom="1.5px solid #F1F2F4">
          <Container maxWidth="7xl" paddingY={["24px"]}>
            <Heading
              color="#4E5457"
              fontSize={[24, 24, 24, 32]}
              fontWeight={400}
            >
              {selectedPeriod}
            </Heading>
          </Container>
        </Box>
        <Box
          paddingY={["24px", "24px", "32px"]}
          borderBottom="1.5px solid #F1F2F4"
        >
          <Container maxW="7xl">
            <HStack
              spacing="23px"
              display={["none", "none", "none", "inline-flex"]}
            >
              <MenuDropOption
                options={periods}
                selectedOption={selectedPeriod}
                setOption={(payload) =>
                  payload === "All"
                    ? setselectedPeriod("")
                    : setselectedPeriod(payload)
                }
              />
              <MenuDropOption
                options={categories}
                selectedOption={
                  !selectedCategory ? "All Sectors" : selectedCategory
                }
                setOption={(payload) =>
                  payload === "All"
                    ? setselectedCategory("")
                    : setselectedCategory(payload)
                }
              />

              <MenuDropOption
                options={stages}
                selectedOption={!selectedStage ? "All Stages" : selectedStage}
                setOption={(payload) =>
                  payload === "All"
                    ? setselectedStage("")
                    : setselectedStage(payload)
                }
              />
            </HStack>
            <HStack display={["flex", "flex", "flex", "none"]}>
              <Button
                padding={0}
                _hover={{ background: "transparent" }}
                _focus={{ outline: "none" }}
                variant="ghost"
                onClick={() => setshowFilter(true)}
                border="1px solid #383838"
                rounded={4}
                width="42px"
                height="33px"
                justifyContent="center"
                alignItems="center"
              >
                <ShowFilterOptionsIcon />
              </Button>
              <Text>Filter</Text>
            </HStack>
          </Container>
        </Box>

        <Container
          maxW="7xl"
          paddingY={["24px", "24px", "40px"]}
          marginBottom="100px"
        >
          <Grid
            m="0 auto"
            gridTemplateColumns={["1fr", "1fr", "1fr", "1fr 1fr 1fr"]}
            gap={["18px", "18px", "18px", "50px"]}
          >
            {loading &&
              [1, 2, 3].map((item) => (
                <Skeleton
                  speed={1}
                  key={`skeleton-${item}`}
                  height="md"
                  width="100%"
                  opacity={0.3}
                />
              ))}
            {startups.map((item) => (
              <StartUpListItem key={item.startup_id} startup={item} />
            ))}
          </Grid>
        </Container>
      </Box>
      {/* filter starts here */}
      <FilterModalMobile show={showFilter} close={() => setshowFilter(false)}>
        <VStack alignItems="flex-start" spacing={8}>
          {/*  */}
          <HStack justifyContent="space-between" width="100%">
            <Box color="#3C4859">
              <Text fontSize={12} fontWeight="400">
                Showing
              </Text>
              <Text fontWeight="500" fontSize={16}>
                {selectedPeriod}
              </Text>
            </Box>
            <Button
              variant="ghost"
              color="#2D7FF9"
              fontWeight="500"
              padding={0}
              _focus={{ outline: "none" }}
              onClick={() => {
                setoptionsType("periods");
                setshowFilter(false);
                setshowOptions(true);
              }}
            >
              Edit
            </Button>
          </HStack>
          {/*  */}
          <HStack justifyContent="space-between" width="100%">
            <Box color="#3C4859">
              <Text fontSize={12} fontWeight="400">
                Showing
              </Text>
              <Text fontWeight="500" fontSize={16}>
                {selectedStage}
              </Text>
            </Box>
            <Button
              variant="ghost"
              color="#2D7FF9"
              fontWeight="500"
              padding={0}
              _focus={{ outline: "none" }}
              onClick={() => {
                setoptionsType("stages");
                setshowFilter(false);
                setshowOptions(true);
              }}
            >
              Edit
            </Button>
          </HStack>
          {/*  */}
          <HStack justifyContent="space-between" width="100%">
            <Box color="#3C4859">
              <Text fontSize={12} fontWeight="400">
                Showing
              </Text>
              <Text fontWeight="500" fontSize={16}>
                {selectedCategory}
              </Text>
            </Box>
            <Button
              variant="ghost"
              color="#2D7FF9"
              fontWeight="500"
              padding={0}
              _focus={{ outline: "none" }}
              onClick={() => {
                setoptionsType("categories");
                setshowFilter(false);
                setshowOptions(true);
              }}
            >
              Edit
            </Button>
          </HStack>
        </VStack>
      </FilterModalMobile>
      {/* filter ends here */}
      {/* stages options */}
      {optionsType === "periods" && (
        <OptionsModal
          options={periods}
          show={showOptions}
          close={() => setshowOptions(false)}
          title="Select Period"
          selected={selectedPeriod}
          selectOption={(payload) => setselectedPeriod(payload)}
        />
      )}
      {optionsType === "stages" && (
        <OptionsModal
          options={stages}
          show={showOptions}
          close={() => setshowOptions(false)}
          title="Select Stages(s)"
          selected={selectedStage}
          selectOption={(payload) => setselectedStage(payload)}
        />
      )}
      {/* categories options */}
      {optionsType === "categories" && (
        <OptionsModal
          options={categories}
          show={showOptions}
          close={() => setshowOptions(false)}
          title="Select Sector(s)"
          selected={selectedCategory}
          selectOption={(payload) => setselectedCategory(payload)}
        />
      )}
    </>
  );
};

export default Startups;
