import api from "../api";
import axios from "axios";

let { VERIFICATION_API } = api;
let baseUrl = VERIFICATION_API;

const token = JSON.parse(localStorage.getItem("token"));

export const getCategories = async () => {
  let response = await axios.get(`${baseUrl}/v1/category/byStartup`, {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getStages = async () => {
  let response = await axios.get(`${baseUrl}/v1/stages/byStartups`, {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getCategoriesInvestments = async (user_id) => {
  let response = await axios.get(
    `${baseUrl}/v1/category/ofInvestor/${user_id}`,
    {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const getStagesInvestments = async (user_id) => {
  let response = await axios.get(`${baseUrl}/v1/stages/ofInvestor/${user_id}`, {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
