import React from "react";
import {
  Box,
  Text,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Skeleton,
  VStack,
  HStack,
  Divider,
} from "@chakra-ui/react";
import PaymentItemTrigger from "../PaymentItemTrigger";
import PaymentItemContent from "../PaymentItemContent";
import { AccordionDownIcon, AccordionUpIcon, BackIcon } from "../Icons";
import numeral from "numeral";
import moment from "moment";
import StatusComponent from "./statusComponent";
import { PrimaryButton } from "../Buttons";

export const PaymentListDesktop = ({
  payments,
  isLoading,
  fetchPaymentHistory,
}) => {
  return (
    <Box display={["none", "none", "none", "block"]} marginTop={5}>
      <HStack mb="22px" p="0 24px" justifyContent="space-between">
        <Text color="#68686C" fontSize={14} w="23%">
          Date
        </Text>
        <Text color="#68686C" fontSize={14} w="23%">
          Startup
        </Text>
        <Text color="#68686C" fontSize={14} w="23%">
          Amount
        </Text>
        <Text color="#68686C" fontSize={14} w="30%">
          Status
        </Text>
      </HStack>

      {/* not loading */}
      {!isLoading && (
        <Accordion allowToggle>
          {payments.map((payment, index) => (
            <AccordionItem
              key={index}
              isDisabled={
                payment.payment_tag === "PAYMENT_ABORTED" ? true : false
              }
            >
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    border="0.25px solid #858585"
                    borderRadius="4px"
                    marginBottom="12px"
                    _focus={{ outline: "none" }}
                    background="transparent"
                    _hover={{}}
                    height="70px"
                    paddingX="24px"
                    display="flex"
                    justifyContent="space-between"
                    textAlign="left"
                    fontWeight="400"
                  >
                    <Text width="23%">
                      {moment(payment.created_at).format("DD-MMM-YYYY")}
                    </Text>

                    <Text width="23%">{payment.name}</Text>
                    <Text width="23%">
                      {numeral(payment.amount).format("$0,0")}
                    </Text>
                    <HStack width="30%" justifyContent="space-between">
                      <Box width="70%">
                        <StatusComponent status={payment.payment_tag} />
                      </Box>
                      <HStack width="30%" justifyContent="flex-end">
                        {isExpanded ? (
                          <AccordionUpIcon />
                        ) : (
                          <AccordionDownIcon />
                        )}
                      </HStack>
                    </HStack>
                  </AccordionButton>

                  <AccordionPanel pb={4} paddingX={0}>
                    <PaymentItemContent
                      {...payment}
                      fetchPaymentHistory={() => fetchPaymentHistory()}
                    />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </Box>
  );
};
