import React from "react";
import { useSelector } from "react-redux"
import { useHistory, Link } from "react-router-dom"
import {
  Box,
  Text,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  Heading,
  HStack,
  // useToast,
} from "@chakra-ui/react"
import { PrimaryButton } from "./Buttons"
import {
  AccordionDownIcon,
  AccordionUpIcon,
  ExchangeRateIcon,
  exchangeRateIcon,
  Plus,
} from "./Icons";
import AccountInformation from "./AccountInformation";
import {
  makePayment,
  updatePayment,
  abortPayment,
  getExchangeRate,
} from "../utils/calls/investments.calls";
import PaymentTimeline from "./PaymentTimeline";
import { TransferLaterTimeline, TransferedTimeline } from "./Icons";

const PaymentItemContent = ({
  status,
  payment_tag,
  invest,
  amount,
  startup_id,
  fetchPaymentHistory,
  id,
  user_id,
  transfered_description,
  transfered_description_date,
  approval_description,
  approval_description_date,
}) => {
  // const toast = useToast()
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [abortLoading, setAbortLoading] = React.useState(false);
  const [exchangeRate, setExchangeRate] = React.useState("");

  const [type, setType] = React.useState("");
  const { user } = useSelector(state => state.user);

  const trustees = [
    {
      name: "Naira Account",
      content: <AccountInformation type='naira' />,
    },
    {
      name: "Dollar Domiciliary Account",
      content: <AccountInformation type='dollar' />,
    },
    {
      name: "Pounds Domiciliary Account (UK)",
      content: <AccountInformation type='pound' />,
    },
  ];

  const initiatePayment = tag => {
    setIsLoading(true);
    const body = {
      amount,
      startup_id,
      user_id: user.id,
    };
    makePayment(tag, body)
      .then(response => {
        setIsLoading(false);
        fetchPaymentHistory();
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const paymentUpdate = tag => {
    setUpdateLoading(true);
    const body = {
      payment_id: id,
      payment_tag: tag,
      user_id,
    };
    updatePayment(body)
      .then(response => {
        setUpdateLoading(false);
        fetchPaymentHistory();
      })
      .catch(error => {
        console.log(error);
        setUpdateLoading(false);
      });
  };

  const handleAbortPayment = () => {
    setAbortLoading(true);
    abortPayment(id)
      .then(response => {
        setAbortLoading(false);
        fetchPaymentHistory();
      })
      .catch(error => {
        console.log(error);
        setAbortLoading(false);
      });
  };

  React.useEffect(() => {
    getExchangeRate().then(res => {
      setExchangeRate(res?.data?.exchange_rate);
    });
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay background='rgba(0,0,0,0.6)' />
        <ModalContent maxWidth='658px' height='448px' rounded={0}>
          <ModalBody
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <VStack spacing={10} maxW='md' mx='auto'>
              <Heading color='#4E5457' fontSize={32} fontWeight='500'>
                {type === "transfered_already"
                  ? "Verifying your payment"
                  : "Waiting for your payment"}
              </Heading>
              <Text
                textAlign='center'
                color='#4E5457'
                fontSize={16}
                fontWeight='400'
              >
                {type === "transfered_already"
                  ? "Thanks for notifying us. We will verify your payment and respond within 24 hours."
                  : "You can notify us when you make the payment to any of the designated accounts."}
              </Text>
              {type === "transfered_already" ? (
                <TransferedTimeline />
              ) : (
                <TransferLaterTimeline />
              )}
              <PrimaryButton
                label='View other startups'
                width={["100%", "auto", "340px", "340px"]}
                onClick={() => {
                  history.push("/dashboard/startups");
                }}
              />
              <HStack>
                <Text color='#3C4859' fontWeight='500'>
                  {type === "transfered_already"
                    ? "Check payment confirmation status"
                    : "Made payment?"}
                </Text>
                <Link
                  to={"/dashboard/investments/payments"}
                  style={{ color: "#2D7FF9", fontWeight: "500" }}
                >
                  {type === "transfered_already" ? "here" : "Notify us here"}
                </Link>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* awaiting payment */}
      {payment_tag === "AWAITING_PAYMENT" && (
        <Box>
          <Text color='#3C4859' fontSize={14} mb='22px'>
            You can transfer to the any of Syndicate trustee accounts below:
          </Text>
          <Accordion allowToggle>
            {trustees.map(trustee => {
              const { name, content } = trustee;
              return (
                <AccordionItem
                  p={["12px", "20px 24px", "20px 24px", "20px 24px"]}
                  border='0.25px solid #858585'
                  borderRadius='4px'
                  mb='12px'
                  key={name}
                >
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        p={0}
                        background='transparent'
                        _hover=''
                        _focus={{ outline: "none" }}
                        _active=''
                      >
                        <Flex
                          width='100%'
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Text color='#3C4859' fontSize={18}>
                            {name}
                          </Text>
                          {isExpanded ? (
                            <AccordionUpIcon />
                          ) : (
                            <AccordionDownIcon />
                          )}
                        </Flex>
                      </AccordionButton>
                      <AccordionPanel pb={4} pl={0} pr={0}>
                        {content.props.type === "naira" && (
                          <Box
                            background='#F7F9FF'
                            alignItems='center'
                            color='#3C4859'
                            p={[
                              "6px 10px",
                              "14px 25px",
                              "14px 25px",
                              "14px 25px",
                            ]}
                            m='8px 0 25px'
                            fontSize={[12, 14, 14, 14]}
                            flexWrap='wrap'
                            fontWeight={600}
                            display='flex'
                          >
                            <ExchangeRateIcon />
                            <Text ml={2}>
                              1 USD = {`${exchangeRate} NGN`}
                              <Box as='span' fontWeight={400} color='#858585'>
                                &nbsp; at the mid-market rate.
                              </Box>
                            </Text>
                          </Box>
                        )}
                        {content}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              );
            })}
          </Accordion>
          <Box
            p={["6px 10px", "14px 25px", "14px 25px", "14px 25px"]}
            mb='22px'
            background='#F9F9F9'
            borderRadius='4px'
          >
            <Text color='#3C4859' fontSize={[12, 12, 12, 14]}>
              Please send the money from an account in your name.{" "}
            </Text>
          </Box>
          {invest ? (
            <Flex justifyContent='space-between' flexWrap='wrap'>
              <PrimaryButton
                disabled={!amount || amount < 5000}
                label='I have transferred to Syndicate'
                width={["100%", "auto", "auto", "auto"]}
                onClick={() => {
                  initiatePayment("transfered_already");
                  setType("transfered_already");
                  onOpen();
                }}
                isLoading={type === "transfered_already" && isLoading}
              />
              <Box height='16px' width='16px' />
              <PrimaryButton
                disabled={!amount || amount < 5000}
                label='I will transfer later'
                width={["100%", "auto", "auto", "auto"]}
                onClick={() => {
                  initiatePayment("transfer_later");
                  setType("transfer_later");
                  onOpen();
                }}
                isLoading={type === "transfer_later" && isLoading}
                outline
              />
            </Flex>
          ) : (
            <Flex
              justifyContent='flex-end'
              flexDirection={["column", "column", "row"]}
              marginBottom={[10]}
            >
              <Box width={["100%", "100%%", "45%", "45%"]}>
                <PrimaryButton
                  label='I have transferred to Syndicate'
                  onClick={() => paymentUpdate("transfered_already")}
                  isLoading={updateLoading}
                />
              </Box>
              <Box
                marginLeft={[0, 0, 5, 5]}
                marginTop={[5, 5, 0, 0]}
                width={["100%", "100%%", "45%", "45%"]}
              >
                <PrimaryButton
                  onClick={handleAbortPayment}
                  label='Abort, I won’t make payment'
                  outline
                  isLoading={abortLoading}
                />
              </Box>
            </Flex>
          )}
        </Box>
      )}
      {/*  pending confirmation */}
      {payment_tag === "PENDING_CONFIRMATION" && (
        <Box>
          <PaymentTimeline
            amount={amount}
            payment_tag={payment_tag}
            transfered_description={transfered_description}
            transfered_description_date={transfered_description_date}
            approval_description={approval_description}
            approval_description_date={approval_description_date}
          />
        </Box>
      )}
      {/* payment confirmed */}
      {payment_tag === "PAYMENT_CONFIRMED" && (
        <Box>
          <PaymentTimeline
            amount={amount}
            payment_tag={payment_tag}
            approval_description={approval_description}
            approval_description_date={approval_description_date}
            transfered_description={transfered_description}
            transfered_description_date={transfered_description_date}
          />
        </Box>
      )}
    </>
  );
};

export default PaymentItemContent;
