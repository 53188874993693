import React, { useEffect, useRef } from 'react'
import { ConsoleLogger as Logger } from '@aws-amplify/core'
import VideoJs from 'video.js'

const logger = new Logger('VideoComponent')

const videoJsOptions = {
  // techOrder: ['html5', 'flash'],
  controls: true,
  autoplay: false,
  fluid: false,
  loop: false,
  width: '100%',
  aspectRatio: '16:9'
}

const VideoPlayer = ({ fileUrl, fileType }) => {
  const videoContainer = useRef()

  //  Setup the player
  useEffect(() => {
    //  Setting content like this because player.dispose() remove also the html content
    videoContainer.current.innerHTML = `
      <div data-vjs-player>
        <video class="video-js" />
      </div>
    `

    const player = VideoJs(videoContainer.current.querySelector('video'), videoJsOptions, async () => {
      logger.debug(`Version of video.js is ${VideoJs.VERSION}`)
      player.src({ src: fileUrl, type: fileType })
    })

    //  When destruct dispose the player
    return () => player.dispose()
  }, [fileUrl, fileType])

  return <div ref={videoContainer} />
}

export default VideoPlayer