import api from "../api";
import axios from "axios";

let { VERIFICATION_API } = api;
let baseUrl = VERIFICATION_API;

const token = JSON.parse(localStorage.getItem("token"));

// retrieve investments
export const retriveUserInvestments = async (userId, category, stage) => {
  let response = await axios.get(
    `${baseUrl}/v1/investments/me/${userId}?category=${category}&stage=${stage}`,
    {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

//make payment
export const makePayment = async (payment_tag, body) => {
  let config = {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  let response = await axios.post(
    `${baseUrl}/v1/payments/create?payment_tag=${payment_tag}`,
    { token, ...body },
    { ...config }
  )
  return response
}

//upDate payment
export const updatePayment = async body => {
  let config = {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  let response = await axios.put(
    `${baseUrl}/v1/payments/update`,
    { token, ...body },
    { ...config }
  )
  return response
}

//abort payment
export const abortPayment = async payment_id => {
  let config = {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  let response = await axios.put(
    `${baseUrl}/v1/payments/abort/${payment_id}`,
    { token },
    { ...config }
  )
  return response
}

//abort payment
export const getExchangeRate = async () => {
  let config = {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  let response = await axios.get(
    `${baseUrl}/v1/foreign_exchange`,
    { token },
    { ...config }
  )
  return response
}

export const retrievePaymentHistory = async userId => {
  let response = await axios.get(`${baseUrl}/v1/payments/get/${userId}`, {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
  return response
}
