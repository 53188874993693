import axios from "axios";
import api from "../api";

let { VERIFICATION_API } = api;
let baseUrl = VERIFICATION_API;

// Sign in
export const signin = async (email, password) => {
  let response = await axios.post(`${baseUrl}/v1/auth/login`, {
    email,
    password,
  });
  return response;
};

// forgot password
export const forgotPassword = async (email) => {
  let response = await axios.post(`${baseUrl}/v1/auth/forget-password`, {
    email,
  });
  return response;
};

// reset
export const resetPassword = async (token, new_password, confirm_password) => {
  let response = await axios.put(`${baseUrl}/v1/auth/reset-password`, {
    token,
    new_password,
    confirm_password,
  });
  return response;
};
