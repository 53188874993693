import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Fonts from "./Fonts";

import { Provider } from "react-redux";
import { store } from "./Redux/store";

// Auth
import AuthLayoutRoute from "./Views/_layouts/authLayout/";

// import SignIn from "./investors/auth/SignIn";
import SignIn from "./Views/Auth/signin";

// investments
import Portfolio from "./Views/Investments/portfolio";
import Payments from "./Views/Investments/payments";

// startups
import Startups from "./Views/Startups/";
import Startup from "./Views/Startups/startup/";
import Invest from "./Views/Startups/invest";
import DocReader from "./Views/Startups/startup/DocReader";

// profile
import MyDetails from "./Views/Profile/myDetails";
import AccountPreference from "./Views/Profile/accountPreference";
import { DashboardLayoutRoute } from "./Views/_layouts/dasboardLayout";

import Notfound from "./Views/Notfound";

import './App.scss'

const theme = extendTheme({
  fonts: {
    heading: "Graphik",
    body: "Graphik",
  },
});

function App() {
  // const { limited_access } = JSON.parse(localStorage.getItem('userData'));
  // console.log(limited_access);
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <Fonts />
        <Router>
          <Switch>
            {/* AUTH */}
            <Redirect exact from="/" to="/signin" />
            {/* sign up */}
            <AuthLayoutRoute exact path="/signin" component={SignIn} />
            {/* DASHBOARD */}
            {/* investment */}
            <Redirect
              exact
              from={["/dashboard", "/dashboard/investments"]}
              to="/dashboard/investments/portfolio"
            />
            <DashboardLayoutRoute
              exact
              path="/dashboard/investments/portfolio"
              component={Portfolio}
            />
            <DashboardLayoutRoute
              exact
              path="/dashboard/investments/payments"
              component={Payments}
            />
            {/* startups */}
            <DashboardLayoutRoute
              exact
              path="/dashboard/startups"
              component={Startups}
            />
            <DashboardLayoutRoute
              exact
              path="/dashboard/startups/doc/:base/:name/:ext"
              component={DocReader}
            />
            <DashboardLayoutRoute
              exact
              path="/dashboard/startups/:name"
              component={Startup}
            />
            {/* <DashboardLayoutRoute
              exact
              path="/dashboard/startups/doc/:name"
              component={DocumentPage}
            /> */}
            <DashboardLayoutRoute
              exact
              path="/dashboard/startups/invest/:name"
              component={Invest}
            />

            {/* profile */}
            <Redirect
              exact
              from="/dashboard/profile"
              to="/dashboard/profile/details"
            />
            <DashboardLayoutRoute
              exact
              path="/dashboard/profile/details"
              component={MyDetails}
            />
            <DashboardLayoutRoute
              exact
              path="/dashboard/profile/account-preference"
              component={AccountPreference}
            />
            <Route component={Notfound} />
          </Switch>
        </Router>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
