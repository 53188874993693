import React from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Image,
  HStack,
  Menu,
  Avatar,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Container,
  Link,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import syndicate_logo from "../../assets/img/syndicate-logo.png";
import { UserIcon, SignoutIcon } from "../../components/Icons";

import "./styles.scss";

const Header = ({ fullName, limited_access }) => {
  const signout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    window.location.replace("/");
  };
  return (
    <>
      <Box
        borderBottom={["none", "none", "none", "1px solid #F1F2F4"]}
        paddingY={["10px", "10px", 0]}
        position="sticky"
        top={0}
        width="100%"
        left={0}
        background="#FFFFFF"
        zIndex={2}
      >
        <Container
          display="flex"
          maxWidth="7xl"
          height={["53px", "53px", "70px", "70px"]}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Link href="/dashboard" _focus={{ outline: "none" }}>
            <Image
              src={syndicate_logo}
              width={["86px", "86px", "115px", "115px"]}
              mr="auto"
            />
          </Link>
          <HStack
            justifyContent="flex-end"
            width="50%"
            spacing={["30px", "30px", "60px", "60px"]}
            height="100%"
          >
            <Box height="100%" display={["none", "none", "none", "block"]}>
              <NavLink
                className="header-menu-link"
                activeClassName="header-menu-link-active"
                to="/dashboard/investments"
              >
                <Text>My Investments</Text>
              </NavLink>
            </Box>
            <Box height="100%" display={["none", "none", "none", "block"]}>
              <NavLink
                className="header-menu-link"
                activeClassName="header-menu-link-active"
                to="/dashboard/startups"
              >
                <Text>New Deals</Text>
              </NavLink>
            </Box>
            {/* display avatar for mobile */}
            <Link
              href="/dashboard/profile"
              display={["block", "block", "none"]}
            >
              <HStack spacing={3}>
                <Avatar
                  boxSize="40px"
                  name={fullName}
                  background="#E8E8E8"
                  color="#3C4859"
                  fontSize={16}
                />
                <Text
                  display={["none", "block"]}
                  fontWeight="500"
                  lineHeight="40px"
                >
                  {fullName}
                </Text>
              </HStack>
            </Link>
            {/* display avatar for laptop and desktop only */}
            <Box display={["none", "none", "block"]}>
              <Menu placement="bottom-end">
                <MenuButton
                  _hover=""
                  _focus={{
                    outline: "none",
                  }}
                  background="transparent"
                  _active=""
                  padding={0}
                  margin={0}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  color="#18131A"
                >
                  <HStack spacing={3}>
                    <Avatar
                      boxSize="40px"
                      name={fullName}
                      background="#E8E8E8"
                      color="#3C4859"
                      fontSize={16}
                    />
                    <Text marginLeft={3}>{fullName}</Text>
                  </HStack>
                </MenuButton>
                <MenuList color="#827F98" minWidth="200px">
                  <Link href="/dashboard/profile">
                    <MenuItem
                      _focus={{
                        outline: "none",
                      }}
                      _hover={{
                        outline: "none",
                      }}
                    >
                      <HStack spacing={4}>
                        <UserIcon fill="#68686C" />
                        <Text color="#68686C" fontWeight="500" marginLeft={2}>
                          Profile
                        </Text>
                      </HStack>
                    </MenuItem>
                  </Link>
                  <MenuItem
                    _focus={{
                      outline: "none",
                    }}
                    _hover={{
                      outline: "none",
                    }}
                    onClick={() => signout()}
                  >
                    <HStack spacing={4}>
                      <SignoutIcon fill="#68686C" />
                      <Text color="#68686C" fontWeight="500" marginLeft={2}>
                        Signout
                      </Text>
                    </HStack>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </HStack>
        </Container>
      </Box>

      {/* nav for mobile */}
      <Flex
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        height="64px"
        background="#FFFFFF"
        alignItems="center"
        justifyContent="space-around"
        display={["flex", "flex", "flex", "none"]}
        borderTop="1px solid #F1F2F4"
        zIndex={1}
      >
        <NavLink
          className="header-menu-link"
          activeClassName="header-menu-link-active"
          to="/dashboard/investments"
        >
          <Text>My Investments</Text>
        </NavLink>
        <NavLink
          className="header-menu-link"
          activeClassName="header-menu-link-active"
          to="/dashboard/startups"
        >
          <Text>New Deals</Text>
        </NavLink>
      </Flex>
    </>
  );
};

export default Header;
