import React from "react";
import { Text, Box, Grid, Heading } from "@chakra-ui/react";

const Item = ({ title, subtitle, isLink }) => {
  return (
    <>
      <Text
        fontSize="14px"
        color="#4E5457"
        textTransform="uppercase"
        marginBottom={2}
      >
        {title}
      </Text>
      <Heading fontSize="16px" color={isLink ? 'blue' : '#4E5457'} fontWeight="500">
        {isLink && <a href={subtitle} target='_black'>{subtitle}</a>}
        {!isLink && subtitle}
      </Heading>
    </>
  );
};

const About = ({ startup }) => {
  return (
    <>
      <Box paddingY={5}>
        <Text>{startup.description}</Text>

        <Grid
          marginTop={14}
          gridTemplateColumns={["1fr", "1fr", "1fr 1fr", "1fr 1fr 1fr"]}
          gap={["18px", "18px", "50px"]}
        >
          <Box>
            <Item title="Legal name" subtitle={startup.legal_name} />
          </Box>
          <Box>
            <Item title="Sector" subtitle={startup.category_name} />
          </Box>
          <Box>
            <Item title="Website" subtitle={startup.website} isLink={true} />
          </Box>
          <Box>
            <Item title="launched" subtitle={startup.launched_at} />
          </Box>
          <Box>
            <Item title="Location" subtitle={startup.location} />
          </Box>
          <Box>
            <Item title="Employees" subtitle={startup.employee_count} />
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default About;
