import React from "react";
import { Box, Flex, Text, HStack, VStack } from "@chakra-ui/react";
import {
  PortfolioIcon,
  PaymentIcon,
  ActiveMenuArrowIcon,
} from "../../components/Icons";
import { NavLink, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { retriveUserInvestments } from "../../utils/calls/investments.calls";
import { abbreviate_number } from "../../utils/helpers";

const Sidebar = ({ empty }) => {
  const { user } = useSelector((state) => state.user);
  const [investments, setinvestments] = React.useState([]);
  const [totalInvested, settotalInvested] = React.useState(0);

  let history = useHistory();
  const menus = [
    {
      name: "My Portfolio",
      path: "/dashboard/investments/portfolio",
      icon: PortfolioIcon,
    },
    {
      name: "Payments",
      path: "/dashboard/investments/payments",
      icon: PaymentIcon,
    },
  ];

  const getInvestments = () => {
    retriveUserInvestments(user.id)
      .then((response) => {
        setinvestments(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getInvestments();
  }, [user]);

  React.useEffect(() => {
    let amount = 0;
    investments.map((investment) => {
      amount = amount + parseInt(investment.investment_amount);
      settotalInvested(amount);
    });
  }, [investments]);

  return (
    <Box
      position={["relative", "relative", "relative", "sticky"]}
      left={[0]}
      top={[0, 0, 0, 24]}
    >
      {/* tabs */}
      {!empty && (
        <Flex
          display={["flex", "flex", "flex", "none"]}
          justifyContent="space-evenly"
          borderBottom="1px solid #F1F2F4"
          marginBottom={5}
        >
          {menus.map((menu, index) => (
            <NavLink
              style={{ width: "50%", textAlign: "center" }}
              to={menu.path}
              key={`list-item-${index}`}
            >
              <Flex
                padding="15px 0px"
                justifyContent="center"
                alignItems="center"
                key={index}
                borderBottom={
                  history.location.pathname === menu.path && "4px solid #2D7FF9"
                }
              >
                <HStack>
                  <menu.icon
                    fill={
                      history.location.pathname === menu.path
                        ? "#2D7FF9"
                        : "#68686C"
                    }
                  />
                  <Text
                    fontWeight={
                      history.location.pathname === menu.path ? "600" : "400"
                    }
                    color={history.location.pathname === menu.path && "#2D7FF9"}
                  >
                    {menu.name}
                  </Text>
                </HStack>
              </Flex>
            </NavLink>
          ))}
        </Flex>
      )}
      {/* tabs end here */}
      <Box position="inherit" top="inherit" marginBottom={[10, 10, 0]}>
        <Flex
          border="0.25px solid #858585"
          p={["16px", "16px", "16px", "32px"]}
          mb="33px"
          flexDirection={["column", "row", "column"]}
          justifyContent="space-between"
        >
          <Box>
            <Text color="#3C4859" fontSize={20} mb="10px" fontWeight="500">
              Hello {user.first_name}
            </Text>
            <Text color="#3C4859" fontSize={14} fontWeight={400}>
              Here is a summary of all your activities
            </Text>
            <Text
              color="#3C4859"
              fontSize={12}
              mb="25px"
              mt="30px"
              display={["none", "none", "none", "block"]}
              fontWeight="500"
            >
              ACTIVITY SUMMARY
            </Text>
          </Box>
          <HStack spacing="60px" mt={["20px", 0, 4, 0]}>
            <VStack spacing="0px" alignItems="flex-start">
              <Text color="#3C4859" fontSize={20} fontWeight="500">
                ${abbreviate_number(parseInt(totalInvested), 0)}
              </Text>
              <Text color="#3C4859" fontSize={14}>
                Invested
              </Text>
            </VStack>
            <VStack spacing="0px" alignItems="flex-start">
              <Text color="#3C4859" fontSize={20} fontWeight="500">
                {!investments ? 0 : investments.length}
              </Text>
              <Text color="#3C4859" fontSize={14}>
                Startups funded
              </Text>
            </VStack>
          </HStack>
        </Flex>
        {!empty && (
          <Box
            display={["none", "none", "none", "block"]}
            border="0.25px solid #858585"
          >
            {menus.map((menu, index) => (
              <NavLink
                to={menu.path}
                style={{ color: "inherit" }}
                key={`list-item-${index}`}
              >
                <Flex
                  padding="20px 20px"
                  justifyContent="space-between"
                  alignItems="center"
                  key={index}
                  borderBottom={index === 0 && "0.25px solid #858585"}
                >
                  <HStack>
                    <menu.icon
                      fill={
                        history.location.pathname === menu.path
                          ? "#2D7FF9"
                          : "#68686C"
                      }
                    />
                    <Text
                      color={
                        history.location.pathname === menu.path && "#2D7FF9"
                      }
                    >
                      {menu.name}
                    </Text>
                  </HStack>
                  {history.location.pathname === menu.path && (
                    <ActiveMenuArrowIcon fill="#2D7FF9" />
                  )}
                </Flex>
              </NavLink>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Sidebar;
