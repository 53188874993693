import React from "react";
import {
  Button,
  Text,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  HStack,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export const MenuDropOption = ({
  placeholder,
  options,
  selectedOption,
  setOption,
}) => {
  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={Button}
        border="1px solid #383838"
        background="transparent"
        _hover=""
        _active={{}}
        _focus={{
          outline: "none",
        }}
      >
        <HStack>
          <Text>{selectedOption}</Text>
          <svg
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.357136 0H10.0774C10.352 0 10.5442 0.356958 10.3245 0.604083L5.57422 6.42525C5.40947 6.64492 5.05251 6.64492 4.88776 6.42525L0.0825522 0.604083C-0.109656 0.356958 0.0550938 0 0.357136 0Z"
              fill="#383838"
            />
          </svg>
        </HStack>
      </MenuButton>
      <MenuList>
        {options.map((option) => (
          <MenuItem
            _focus={{ outline: "none" }}
            _hover={{
              outline: "none",
              background: "#eee",
            }}
            key={option.id}
            onClick={() => setOption(option.name)}
          >
            <Text marginLeft={2}>{option.name}</Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export const SortDropOption = ({}) => {
  return (
    <HStack fontSize="14px">
      <Text color="rgba(38, 38, 38, 0.8)">Sort by</Text>
      <Menu placement="bottom-start">
        <MenuButton
          as={Button}
          border="none"
          background="transparent"
          _hover=""
          _active={{}}
          _focus={{
            outline: "none",
          }}
          padding={0}
          rightIcon={<ChevronDownIcon />}
        >
          <Text>Date</Text>
        </MenuButton>
        <MenuList minW="100px">
          {["Date", "A-Z", "Z-A"].map((option) => (
            <MenuItem
              _focus={{ outline: "none" }}
              _hover={{
                outline: "none",
                background: "#eee",
              }}
              key={option.id || option}
            >
              <Text marginLeft={2}>{option.name || option}</Text>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </HStack>
  );
};

// export const OptionsButtonSmall = ({ label, options = [], onClick }) => {
//   return (
//     <Menu>
//       <MenuButton
//         _hover=""
//         _focus={{
//           outline: "none",
//         }}
//         _active=""
//         padding={0}
//         height="auto"
//         background="transparent"
//         margin={0}
//         as={Button}
//         rightIcon={<ChevronDownIcon />}
//         color="#262626"
//         fontSize={14}
//       >
//         {label}
//       </MenuButton>
//       <MenuList color="#827F98" position="relative" left={-5} minWidth="200px">
//         {options.map((option) => (
//           <MenuItem
//             _focus={{ outline: "none" }}
//             _hover={{
//               outline: "none",
//             }}
//             key={option}
//           >
//             <Text marginLeft={2}>{option}</Text>
//           </MenuItem>
//         ))}
//       </MenuList>
//     </Menu>
//   );
// };
