import api from "../api";
import axios from "axios";

let { VERIFICATION_API } = api;
let baseUrl = VERIFICATION_API;

const token = JSON.parse(localStorage.getItem("token"));

// retrieve startups
export const retrieveStartups = async (period, category, stage) => {
  let qs=[];
  if(period) qs[qs.length] = `period=${period}`;
  if(category) qs[qs.length] = `category=${category}`;
  if(stage) qs[qs.length] = `stage=${stage}`;

  let response = await axios.get(
    `${baseUrl}/v1/startup?${qs.join('&')}`,
    {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

// retrieve a startup
export const retrieveStartup = async (startupId) => {
  let response = await axios.get(`${baseUrl}/v1/startup/${startupId}`, {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

// retrieve startup faq
export const retrieveStartupFaq = async (startupId) => {
  let response = await axios.get(`${baseUrl}/v1/faq/${startupId}`, {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

// retrieve startup media
export const retrieveStartupMedia = async (startupId) => {
  let response = await axios.get(`${baseUrl}/v1/uploads/${startupId}`, {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
