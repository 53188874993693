import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Heading,
} from "@chakra-ui/react";
import { AccordionDownIcon, AccordionUpIcon } from "../../../components/Icons";

const Faqs = ({startupFaqs}) => {
  return (
    <Box paddingY={5}>
      <Accordion allowMultiple>
        {startupFaqs.map((item, index) => (
          <AccordionItem
            key={index}
            border="1px solid #F1F1F1"
            marginBottom="20px"
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  _expanded={{ bg: "#F9F9F9" }}
                  paddingY="20px"
                  paddingX="30px"
                  _focus={{ outline: "none" }}
                >
                  <Heading
                    fontWeight="500"
                    fontSize="18px"
                    flex="1"
                    textAlign="left"
                  >
                    {item.question}
                  </Heading>
                  {isExpanded ? <AccordionUpIcon /> : <AccordionDownIcon />}
                </AccordionButton>
                <AccordionPanel paddingX="30px" paddingY="15px">
                  {item.answer}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default Faqs;
