import React from "react";
import {
  Box,
  Text,
  Button,
  Flex,
  HStack,
  Skeleton,
  Container,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  ShowFilterOptionsIcon,
  RadioSelected,
  RadioUnselected,
} from "../../components/Icons";
import Sidebar from "./sidebar";
import { retrievePaymentHistory } from "../../utils/calls/investments.calls";
import { useSelector } from "react-redux";
import { MenuDropOption, SortDropOption } from "../../components/Options";
import { PaymentListMobile } from "../../components/PaymentList/Mobile";
import { PaymentListDesktop } from "../../components/PaymentList/Desktop";
import FilterModalMobile from "../../components/MobileModals/FilterModalMobile";
import { PrimaryButton } from "../../components/Buttons";
// import { options } from "numeral";

const Payments = () => {
  const [payments, setPayments] = React.useState([]);
  const [loading, setloading] = React.useState(true);
  const { user } = useSelector((state) => state.user);
  const [showFilter, setshowFilter] = React.useState(false);
  const toast = useToast();
  const options = [
    "All Payments",
    "Awaiting Payment",
    "Pending Confirmation",
    "Payment Confirmed",
  ];
  const [tempSelectedOption, settempSelectedOption] = React.useState(
    options[0]
  );

  const fetchHistory = (userId) => {
    retrievePaymentHistory(userId)
      .then((res) => {
        setPayments(res.data);
        setloading(false);
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
      });
  };

  const TransferedToSyn = () => {
    fetchHistory(user.id);
    toast({
      title: "Request sent",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  React.useEffect(() => {
    fetchHistory(user.id);
  }, [user]);

  return (
    <>
      <Container maxW='7xl' marginTop={[0, 0, 3, 14]}>
        <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
          <Box width={["100%", "100%", "100%", "30%"]}>
            <Sidebar empty={false} />
          </Box>
          <Box width={["100%", "100%", "100%", "63%"]}>
            {/* loading */}
            {loading && (
              <Box pb='100px'>
                <VStack spacing={["18px", "18px", "18px", 5]}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => (
                    <Skeleton
                      h={["93px", "93px", "93px", "70px"]}
                      w='100%'
                      speed={1}
                      opacity={0.4}
                      key={`skeleton-${item}`}
                    />
                  ))}
                </VStack>
              </Box>
            )}

            {/* loaded, not empty */}
            {!loading && payments.length > 0 && (
              <Box>
                <HStack
                  borderBottom='0.25px solid #858585'
                  pb={[3, 3, 3, 3]}
                  mb={[5, 5, 5, 0]}
                >
                  <Text
                    mr='auto'
                    color='#3C4859'
                    fontSize={[18, 18, 32, 32]}
                    fontWeight='500'
                  >
                    Payments
                  </Text>
                  <HStack
                    display={["none", "none", "none", "flex"]}
                    spacing={5}
                  >
                    <MenuDropOption
                      options={options}
                      placeholder='All Payments'
                      selectedOption={tempSelectedOption}
                      setOption={payload => settempSelectedOption(payload)}
                    />
                  </HStack>

                  <Button
                    padding={0}
                    _hover={{ background: "transparent" }}
                    _focus={{ outline: "none" }}
                    variant='ghost'
                    display={["flex", "flex", "flex", "none"]}
                    onClick={() => setshowFilter(true)}
                    border='1px solid #383838'
                    rounded={4}
                    width='42px'
                    height='33px'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <ShowFilterOptionsIcon />
                  </Button>
                </HStack>
                {/* sort */}
                <Box
                  marginTop={5}
                  display={["none", "none", "none", "block"]}
                  borderBottom='0.5px solid #858585'
                  paddingBottom={5}
                >
                  <SortDropOption />
                </Box>
                <PaymentListMobile
                  payments={payments}
                  fetchPaymentHistory={() => TransferedToSyn()}
                />

                <PaymentListDesktop
                  payments={payments}
                  fetchPaymentHistory={() => TransferedToSyn()}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Container>
      {/* filter */}
      <FilterModalMobile show={showFilter} close={() => setshowFilter(false)}>
        <VStack alignItems='flex-start' spacing={4} marginBottom={4}>
          {options.map(option => (
            <HStack
              width='100%'
              paddingY={4}
              justifyContent='space-between'
              onClick={() => settempSelectedOption(option)}
              cursor='pointer'
            >
              <Text
                color={tempSelectedOption === option ? "#2D7FF9" : "#3C4859"}
                fontWeight={tempSelectedOption === option && "600"}
              >
                {option}
              </Text>
              {tempSelectedOption === option ? (
                <RadioSelected />
              ) : (
                <RadioUnselected />
              )}
            </HStack>
          ))}
        </VStack>
        <PrimaryButton
          label='Save Changes'
          onClick={() => setshowFilter(false)}
        />
      </FilterModalMobile>
      {/* filter ends here */}
    </>
  )
};

export default Payments;
