import React from "react";
import { Box, Divider, HStack, Text } from "@chakra-ui/react";
import { BackIcon, RadioSelected, RadioUnselected } from "../Icons";
import { PrimaryButton } from "../Buttons";

const OptionsModal = ({
  show,
  title,
  options,
  close,
  selected,
  selectOption,
}) => {
  const [tempSelected, settempSelected] = React.useState(selected);
  return (
    <Box display={["block", "block", "block", "none"]}>
      <Box
        display={show ? "block" : "none"}
        background="white"
        height="100vh"
        width="100%"
        position="fixed"
        top={0}
        left={0}
        zIndex={1}
        paddingTop="73px"
      >
        <HStack
          background="#FAFBFD"
          padding="20px 20px"
          onClick={() => {
            close();
            settempSelected(selected);
          }}
          cursor="pointer"
        >
          <BackIcon />
          <Text fontWeight="600" fontSize="14px">
            Back to My Portfolio
          </Text>
        </HStack>
        <Box height="100%" overflowY="scroll">
          <Text paddingX="20px" paddingY={4}>
            {title}
          </Text>
          <Divider />
          <Box paddingTop={4} paddingBottom={48} paddingX="20px">
            {options.map((option) => (
              <HStack
                key={option.id}
                paddingY={4}
                justifyContent="space-between"
                onClick={() => settempSelected(option.name)}
              >
                <Text
                  color={tempSelected === option.name ? "#2D7FF9" : "#3C4859"}
                  fontWeight={tempSelected === option.name && "600"}
                >
                  {option.name}
                </Text>
                {tempSelected === option.name ? (
                  <RadioSelected />
                ) : (
                  <RadioUnselected />
                )}
              </HStack>
            ))}
          </Box>
        </Box>
        <Box
          background="white"
          style={{ boxShadow: "0px -1px 44px rgba(28, 28, 28, 0.2)" }}
          position="fixed"
          bottom={0}
          width="100%"
          paddingY={5}
          paddingX="20px"
        >
          <PrimaryButton
            label="Save Changes"
            onClick={() => {
              selectOption(tempSelected);
              close();
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OptionsModal;
