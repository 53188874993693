import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Flex,
  Grid,
  Heading,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { StartupPageCard } from "../../../components/StartUpCard";
import { NavLink, useLocation } from "react-router-dom";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import About from "./about";
import Pitch from "./pitch";
import Team from "./team";
import Faqs from "./faqs";
import {
  retrieveStartup,
  retrieveStartupMedia,
  retrieveStartupFaq,
} from "../../../utils/calls/startups.calls";

const Startup = () => {
  const { user } = useSelector((state) => state.user);
  const { limited_access } = user;

  const location = useLocation();
  const [startup, setstartup] = React.useState({});
  const [startupMedia, setStartUpMedia] = React.useState([]);
  const [startupFaqs, setstartupFaqs] = React.useState([]);

  const selectedTabStyle = {
    borderColor: "#2D7FF9",
    borderBottomWidth: "4px",
    fontWeight: "600",
    paddingY: "15px",
    paddingX: "30px",
    color: "#2D7FF9",
  };

  React.useEffect(() => {
    const id = location.pathname.split("/startups/")[1];

    retrieveStartupFaq(id)
      .then((response) => {
        setstartupFaqs(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    retrieveStartup(id)
      .then((response) => {
        setstartup(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    retrieveStartupMedia(id)
      .then((response) => {
        setStartUpMedia(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location.pathname]);

  return (
    <>
      <Box background="#FAFBFD" paddingY={["24px"]} width="100%">
        <Container height="100%" maxWidth="7xl" alignItems="center">
          <NavLink to="/dashboard/startups">
            <Flex alignItems="center" width="auto" cursor="pointer">
              <ArrowBackIcon boxSize="16px" />{" "}
              <Heading
                fontWeight="500"
                color="#4E5457"
                fontSize={16}
                lineHeight="24px"
                ml="8px"
              >
                Back to Startups
              </Heading>
            </Flex>
          </NavLink>
        </Container>
      </Box>
      <Container
        maxW="7xl"
        paddingY={["24px", "24px", "40px"]}
        paddingBottom={["100px", "50px"]}
      >
        <Grid
          gridTemplateColumns={["1fr", "1fr", "1fr", "1fr 2fr"]}
          gap={[0, 0, 0, "50px"]}
        >
          <Box>
            <StartupPageCard
              startup={startup}
              limited_access={limited_access}
            />
          </Box>
          {/* tabs */}
          <Box border="0.25px solid #858585" rounded={4}>
            <Tabs>
              <TabList
                justifyContent="space-evenly"
                borderBottom="0.25px solid #858585"
                color="#68686C"
              >
                <Tab _selected={selectedTabStyle} _focus={{ outline: "none" }}>
                  About
                </Tab>
                {!limited_access && (
                  <Tab
                    _selected={selectedTabStyle}
                    _focus={{ outline: "none" }}
                  >
                    Pitch
                  </Tab>
                )}
                <Tab _selected={selectedTabStyle} _focus={{ outline: "none" }}>
                  Team
                </Tab>
                {startupFaqs.length > 0 && (
                  <Tab
                    _selected={selectedTabStyle}
                    _focus={{ outline: "none" }}
                  >
                    FAQs
                  </Tab>
                )}
              </TabList>

              <TabPanels paddingX={["0", "10px", "40px"]}>
                <TabPanel>
                  <About startup={startup} />
                </TabPanel>
                {!limited_access && (
                  <TabPanel>
                    <Pitch startup={startup} media={startupMedia} />
                  </TabPanel>
                )}
                <TabPanel>
                  <Team startup={startup} />
                </TabPanel>
                {startupFaqs.length > 0 && (
                  <TabPanel>
                    <Faqs startupFaqs={startupFaqs} />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </Box>
        </Grid>
      </Container>
    </>
  );
};

export default Startup;
