import React from "react";
import { Box, Text, Flex, Input } from "@chakra-ui/react";

export const TextInput = ({
  label,
  placeholder,
  type = "text",
  handleInput,
  left,
  color,
  borderColor,
  isDisbled,
  value,
  borderState,
  maxLength,
  readOnly
}) => {
  return (
    <Box
      width='100%'
      borderWidth='0.5px'
      borderStyle={borderState || "solid"}
      borderColor={borderColor || "#858585"}
      borderRadius='4px'
      p='10px 15px 12px'
    >
      <Text
        color={color || "#858585"}
        fontSize={14}
        fontWeight={400}
        lineHeight='143.16%'
        margin={0}
      >
        {label}
      </Text>
      {left ? (
        <Flex color={color || "#858585"}>
          {left}
          <Input
            placeholder={placeholder}
            color={color || "#858585"}
            type={type}
            padding={0}
            height='auto'
            border={0}
            fontWeight='500'
            value={value}
            onChange={e => handleInput(e.target.value)}
            disabled={isDisbled || false}
            maxLength={maxLength || ""}
            readOnly={readOnly || false}
          />
        </Flex>
      ) : (
        <Input
          placeholder={placeholder}
          color={color || "#858585"}
          type={type}
          padding={0}
          height='auto'
          border={0}
          fontWeight='500'
          value={value}
          onChange={e => handleInput(e.target.value)}
          disabled={isDisbled || false}
          maxLength={maxLength || ""}
          readOnly={readOnly || false}
        />
      )}
    </Box>
  );
};

export const PasswordInput = ({ label, handleInput }) => {
  const [type, setType] = React.useState("password");
  return (
    <Box
      width='100%'
      borderWidth='0.5px'
      borderStyle='solid'
      borderColor='#858585'
      borderRadius='4px'
      p='10px 15px 12px'
    >
      <Flex alignItems='center' justifyContent='space-between'>
        <Box width='100%'>
          <Text
            color='#858585'
            fontSize={14}
            fontWeight={400}
            lineHeight='143.16%'
            margin={0}
          >
            {label}
          </Text>
          <Input
            placeholder='********'
            type={type}
            padding={0}
            height='auto'
            border={0}
            fontWeight='500'
            onChange={e => handleInput(e.target.value)}
          />
        </Box>
        <Text
          onClick={
            type === "password"
              ? () => setType("text")
              : () => setType("password")
          }
          m={0}
          cursor='pointer'
          color='#3C4859'
        >
          {type === "password" ? "SHOW" : "HIDE"}
        </Text>
      </Flex>
    </Box>
  );
};
