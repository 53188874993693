import React from "react";
import {
  Flex,
  Box,
  Text,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  useToast,
} from "@chakra-ui/react";
import { forgotPassword } from "../utils/calls/auth.calls";
import { TextInput } from "../components/Inputs";
import { PrimaryButton } from "../components/Buttons";

const ForgotPassword = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setisLoading] = React.useState(false);
  const [email, setemail] = React.useState("");

  const handleForgotPassword = () => {
    setisLoading(true);
    forgotPassword(email)
      .then((response) => {
        let { data } = response;
        setisLoading(false);
        onClose();
        toast({
          title: `${data.data.message}`,
          status: "success",
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "User does not exist",
          status: "error",
          isClosable: true,
        });
        setisLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Text
        textAlign="right"
        color="#0148FF"
        fontSize={14}
        fontWeight={400}
        cursor="pointer"
        onClick={onOpen}
        marginBottom={10}
      >
        Forgot Password?
      </Text>

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent
          borderRadius={0}
          p={["34px 5% 150px", "34px 5% 150px", "51px 105px", "51px 105px"]}
          maxWidth={["100%", "90%", "658px", "658px"]}
          m={["auto 0 0", "initial"]}
        >
          <Text
            textAlign={["left", "center"]}
            color="#4E5457"
            fontSize={[22, 32]}
            fontWeight={400}
            lineHeight="32px"
            mb={["25px", "30px"]}
          >
            Forgot Password?
          </Text>
          <Text
            textAlign={["left", "center"]}
            color="#4E5457"
            fontSize={16}
            fontWeight={400}
            mb={["25px", "30px"]}
          >
            Enter the email account you use for your Syndicate account below and
            we will send you a password reset link
          </Text>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleForgotPassword();
            }}
          >
            <Stack width={["100%", "340px"]} m="0 auto 26px" spacing="30px">
              <TextInput
                label="Email Address"
                placeholder="example@email.com"
                handleInput={(payload) => setemail(payload)}
              />
              <PrimaryButton
                type="submit"
                disabled={!email || isLoading}
                label="Request Reset Link"
              />
            </Stack>
          </form>
          <Flex
            justifyContent="center"
            color="#3C4859"
            fontSize={14}
            fontWeight={400}
          >
            <Text>Remembered your password?</Text>
            <Text color="#2D7FF9" cursor="pointer" onClick={onClose}>
              {" "}
              Try logging in
            </Text>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ForgotPassword;
