import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Box, Flex, Text, HStack } from "@chakra-ui/react";
import {
  SettingsIcon,
  LogoutIcon,
  UserIcon,
  ActiveMenuArrowIcon,
} from "../../components/Icons";
import "./styles.scss";

const Sidebar = () => {
  const history = useHistory();

  const menus = [
    {
      name: "My Details",
      path: "/dashboard/profile/details",
      icon: UserIcon,
    },
    {
      name: "Account Preference",
      path: "/dashboard/profile/account-preference",
      icon: SettingsIcon,
    },
  ];

  const signout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    window.location.replace("/");
  };

  return (
    <Box>
      <Box border="0.25px solid #858585">
        {menus.map((menu, index) => (
          <NavLink
            to={menu.path}
            className="menu-link"
            activeClassName="menu-link-active"
            key={`${menu.name}-link-item`}
          >
            <Flex
              padding="20px 20px"
              justifyContent="space-between"
              alignItems="center"
              key={index}
              borderBottom={index === 0 && "0.25px solid #858585"}
            >
              <HStack>
                <menu.icon
                  fill={
                    history.location.pathname === menu.path
                      ? "#2D7FF9"
                      : "#68686C"
                  }
                />
                <Text>{menu.name}</Text>
              </HStack>
              {history.location.pathname === menu.path && (
                <ActiveMenuArrowIcon fill="#2D7FF9" />
              )}
            </Flex>
          </NavLink>
        ))}
      </Box>

      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginTop={10}
        border="0.25px solid #858585"
        padding="20px 20px"
        marginBottom={[10, 10, 0]}
        cursor="pointer"
        _hover={{ background: "#eee" }}
        onClick={() => signout()}
      >
        <HStack>
          <LogoutIcon fill="#68686C" />
          <Text color="#68686c" fontWeight="400">
            Log out
          </Text>
        </HStack>
      </Flex>
    </Box>
  );
};

export default Sidebar;
