import React from "react";
import DashboardNav from "../../../components/dashboardNav";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

export function DashboardLayout({ children }) {
  const { user } = useSelector((state) => state.user);

  return (
    <>
      <DashboardNav fullName={user.first_name + " " + user.last_name} />
      {children}
    </>
  );
}

export const DashboardLayoutRoute = ({ component: Component, ...rest }) => {
  const token = JSON.parse(localStorage.getItem("token"));

  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          return (
            <DashboardLayout>
              <Component {...props} />
            </DashboardLayout>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
