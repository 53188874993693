import React from "react";
import {
  Text,
  Image,
  Box,
  Grid,
  HStack,
  Heading,
  VStack,
  Link,
  Circle,
} from "@chakra-ui/react";
import { TwitterIcon, LinkedInIcon } from "../../../components/Icons";

const Item = ({ member }) => {
  // console.log(member);
  return (
    <Box border="1px solid #F1F1F1" padding={8}>
      <HStack spacing={2}>
        <Box width="35%">
          <Image src={member.image_url} alt="photo" width="87px" rounded="4px" />
        </Box>
        <VStack width="60%" alignItems="flex-start">
          <Text fontSize="14px" color="#3C4859">
            {member.first_name} {member.last_name}
          </Text>
          <Heading fontSize="14px" color="#3C4859" fontWeight="500">
            {member.position}
          </Heading>
          <HStack>
            {
              member.linkedin &&
              <Circle height="22px" width="22px" background="#DCDCDC">
                <Link href={member.linkedin}>
                  <LinkedInIcon />
                </Link>
              </Circle>
            }
            {
              member.twitter &&
              <Circle height="22px" width="22px" background="#DCDCDC">
                <Link href={member.twitter}>
                  <TwitterIcon />
                </Link>
              </Circle>
            }
          </HStack>
        </VStack>
      </HStack>
      <Text fontSize="14px" color="#4E5457" marginTop={3}>
        {member.description}
      </Text>
    </Box>
  );
};

const Team = ({ startup }) => {
  return (
    <>
      <Grid
        marginY={8}
        gridTemplateColumns={["1fr", "1fr", "1fr 1fr"]}
        gap={["18px", "18px", "30px"]}
      >
        {startup.team_members &&
          startup.team_members.map((member) => <Item member={member} key={`${member.first_name}-${member.lasst_name}-card`}/>)}
      </Grid>
    </>
  );
};

export default Team;
