import React from "react";
import { Box, Text, Flex, Divider } from "@chakra-ui/react";

const AccountInformation = ({type}) => {
  const accountDetails = {
    naira: {
      accountName: 'FBNQT/CcHub Investment Trust',
      accountNumber: '2036402436',
      beneficiaryAccountNumber: '3582-059979-001'
    },
    dollar: {
      accountName: 'FBNQT/CcHub Investment Trust',
      accountNumber: '2036437298',
      beneficiaryAccountNumber: '3582-059974-001'
    },
    pound: {
      accountName: 'FBN Trustees Nigeria Ltd',
      accountNumber: '100587-USD-CACORP-02',
      beneficiaryAccountNumber: '3582-059974-001'
    }
  }

  return (
    <Box background="#F9F9F9" padding={8}>
      {/*  */}
      <Flex flexWrap="wrap">
        <Box flexGrow={1} color="#3C4859" marginBottom={5} mr="20px">
          <Text fontWeight="400" fontSize={12}>Account Name</Text>
          <Text fontWeight="600" fontSize={14}>{accountDetails[type].accountName}</Text>
        </Box>
        <Box color="#3C4859" marginBottom={5}>
          <Text fontWeight="400" fontSize={12}>Account Number</Text>
          <Text fontWeight="600" fontSize={14}>{accountDetails[type].accountNumber}</Text>
        </Box>
        <Box width="100%" color="#3C4859" marginBottom={5}>
          <Text fontWeight="400" fontSize={12}>Bank</Text>
          <Text fontWeight="600" fontSize={14}>First Bank of Nigeria Ltd</Text>
        </Box>
      </Flex>
      <Divider borderColor="#858585" marginBottom={4} />
      <Flex flexWrap="wrap">
        <Box flexGrow={1} color="#3C4859" marginBottom={5} mr="20px">
          <Text fontWeight="400" fontSize={12}>Correspondent Bank</Text>
          <Text fontWeight="600" fontSize={14}>Standard Chartered Bank, New York</Text>
        </Box>
        <Box color="#3C4859" marginBottom={5}>
          <Text fontWeight="400" fontSize={12}>Swift Code</Text>
          <Text fontWeight="600" fontSize={14}>SCBLUS33</Text>
        </Box>
        <Box width="100%" color="#3C4859" marginBottom={5}>
          <Text fontWeight="400" fontSize={12}>ABA Number</Text>
          <Text fontWeight="600" fontSize={14}>026002561</Text>
        </Box>
      </Flex>
      <Divider borderColor="#858585" marginBottom={4} />
      <Flex flexWrap="wrap">
        <Box flexGrow={1} color="#3C4859" marginBottom={5} mr="20px">
          <Text fontWeight="400" fontSize={12}>Beneficiary Institution</Text>
          <Text fontWeight="600" fontSize={14}>First Bank of Nigeria Ltd </Text>
        </Box>
        <Box color="#3C4859" marginBottom={5}>
          <Text fontWeight="400" fontSize={12}>Swift Code</Text>
          <Text fontWeight="600" fontSize={14}>FBNINGLA</Text>
        </Box>
        <Box width="100%" color="#3C4859">
          <Text fontWeight="400" fontSize={12}>Account Number</Text>
          <Text fontWeight="600" fontSize={14}>{accountDetails[type].beneficiaryAccountNumber}</Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default AccountInformation;
