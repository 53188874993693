import React from "react";
import moment from "moment"
import { Box, Text, Circle, HStack, Flex } from "@chakra-ui/layout"
import "./styles.scss"

const PaymentTimeline = ({
  transfered_description_date,
  approval_description_date,
  transfered_description,
  payment_tag,
  approval_description,
}) => {
  const timelines = [
    {
      date: transfered_description_date,
      description: transfered_description,
    },
    {
      date: approval_description_date,
      description: approval_description,
    },
  ]

  return (
    <>
      <Flex
        flexDirection='column'
        marginY={5}
        display={["none", "none", "none", "flex"]}
      >
        {timelines.map((timeline, index) => (
          <HStack
            justifyContent='space-evenly'
            width='100%'
            key={`timeline-item-${index}`}
            alignItems='flex-start'
          >
            <Box width='20%'>
              {timeline.date !== null && (
                <Text textAlign='right'>
                  {moment(timeline.date).format("ddd, DD MMM YYYY, hA")}
                </Text>
              )}
            </Box>

            <Flex
              flexDirection='column'
              alignItems='center'
              justifyContent='flex-start'
            >
              {timeline.description !== null && (
                <>
                  <Circle
                    width='12px'
                    height='12px'
                    background={
                      payment_tag === "PENDING_CONFIRMATION"
                        ? "#FF7A00"
                        : "#01A85A"
                    }
                  />
                  <Box
                    display={
                      payment_tag === "PAYMENT_CONFIRMED" &&
                      index === timelines.length - 1
                        ? "none"
                        : "block"
                    }
                    height='50px'
                    borderWidth={1}
                    borderColor={
                      payment_tag === "PENDING_CONFIRMATION"
                        ? index === timelines.length - 1
                          ? "#D3D5D8"
                          : "#FF7A00"
                        : "#01A85A"
                    }
                  />
                </>
              )}
            </Flex>
            <Box width='75%'>
              {timeline.description !== null && (
                <Text
                  color={
                    index === timelines.length - 1 &&
                    payment_tag === "PAYMENT_CONFIRMED" &&
                    "#01A85A"
                  }
                  fontWeight={
                    index === timelines.length - 1 &&
                    payment_tag === "PAYMENT_CONFIRMED"
                      ? "500"
                      : "400"
                  }
                >
                  {timeline.description}
                </Text>
              )}
            </Box>
          </HStack>
        ))}
        {/* empty placeholder */}
        {payment_tag === "PENDING_CONFIRMATION" && (
          <HStack
            display={["none", "none", "none", "flex"]}
            justifyContent='space-evenly'
            width='100%'
          >
            <Box width='20%'></Box>
            <HStack justifyContent='center' position='relative'>
              <Circle width='12px' height='12px' background='#D3D5D8' />
            </HStack>
            <Box width='75%'>
              <HStack spacing={4}>
                <Box background='#D3D5D8' width='100px' height='6px'></Box>
                <Box background='#D3D5D8' width='33px' height='6px'></Box>
              </HStack>
            </Box>
          </HStack>
        )}
      </Flex>

      {/* mobile */}
      <Box display={["block", "block", "block", "none"]} marginTop={5}>
        {timelines.map((timeline, index) => (
          <Box position='relative'>
            <HStack alignItems='flex-start' spacing={5}>
              <Flex
                flexDirection='column'
                alignItems='center'
                justifyContent='flex-start'
                Bottom={10}
                height='150px'
              >
                <Circle
                  width='12px'
                  height='12px'
                  background={
                    payment_tag === "PENDING_CONFIRMATION"
                      ? "#FF7A00"
                      : "#01A85A"
                  }
                />
                <Box
                  height='100%'
                  borderWidth='2px'
                  borderColor={
                    payment_tag === "PENDING_CONFIRMATION"
                      ? index === timelines.length - 1
                        ? "#D3D5D8"
                        : "#FF7A00"
                      : "#01A85A"
                  }
                ></Box>
              </Flex>
              <Box position='relative'>
                <Text position='absolute' top='-5px'>
                  {moment(timeline.date).format("ddd, DD MMM YYYY, hA")}
                </Text>
                <Box
                  background='#F9F9F9'
                  padding={3}
                  marginTop={3}
                  position='relative'
                  top={5}
                >
                  {timeline.description}
                </Box>
              </Box>
            </HStack>
          </Box>
        ))}
        {/* empty placeholder */}
        {payment_tag === "PENDING_CONFIRMATION" && (
          <HStack alignItems='center' spacing={5}>
            <Circle width='12px' height='12px' background='#D3D5D8' />
            <HStack spacing={2}>
              <Box background='#D3D5D8' width='100px' height='6px'></Box>
              <Box background='#D3D5D8' width='33px' height='6px'></Box>
            </HStack>
          </HStack>
        )}
      </Box>
    </>
  )
}

export default PaymentTimeline;
