import React from "react";
import { Box } from "@chakra-ui/react";

const StatusComponent = ({ status }) => {
  return (
    <>
      {/* awaiting */}
      {status === "AWAITING_PAYMENT" && (
        <Box
          background="rgba(244, 44, 31, 0.2)"
          color="#F42C1F"
          paddingY={[1, 1, 2]}
          fontWeight="500"
          paddingX={2}
          fontSize="12px"
          rounded="2px"
          textAlign="center"
        >
          Awaiting Payment
        </Box>
      )}
      {/* pending */}
      {status === "PENDING_CONFIRMATION" && (
        <Box
          background="rgba(255, 122, 0, 0.2)"
          color="#FF7A00"
          paddingY={[1, 1, 2]}
          fontWeight="500"
          paddingX={2}
          fontSize="12px"
          rounded="2px"
          textAlign="center"
        >
          Pending Confirmation
        </Box>
      )}
      {/* confitmed */}
      {status === "PAYMENT_CONFIRMED" && (
        <Box
          background="rgba(1, 168, 90, 0.2)"
          color="#01A85A"
          paddingY={[1, 1, 2]}
          fontWeight="500"
          paddingX={2}
          fontSize="12px"
          rounded="2px"
          textAlign="center"
        >
          Payment Confirmed
        </Box>
      )}
      {/* payment aborted */}
      {status === "PAYMENT_ABORTED" && (
        <Box
          background="red"
          color="#fff"
          paddingY={[1, 1, 2]}
          fontWeight="500"
          paddingX={2}
          fontSize="12px"
          rounded="2px"
          textAlign="center"
        >
          Payment Aborted
        </Box>
      )}
    </>
  );
};

export default StatusComponent;
