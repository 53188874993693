import React from "react";
import { Box, Text, Stack, useToast } from "@chakra-ui/react";
import { PasswordInput, TextInput } from "../../components/Inputs";
import { PrimaryButton } from "../../components/Buttons";
import AddTitleHelmet from "../../components/Helmet";
import { signin } from "../../utils/calls/auth.calls";
import ForgotPassword from "../../components/ForgotPassword";
import ResetPassword from "../../components/ResetPassword";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const SignInPage = () => {
  const location = useLocation();
  const parsedQuery = queryString.parse(location.search);
  const toast = useToast();
  const [email, setemail] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [isLoading, setisLoading] = React.useState(false);
  const [showResetPassword, setshowResetPassword] = React.useState(false);

  React.useEffect(() => {
    parsedQuery.ref === "reset-password"
      ? setshowResetPassword(true)
      : setshowResetPassword(false);
  }, [parsedQuery]);

  const handleSubmission = () => {
    setisLoading(true);
    signin(email, password)
      .then(({ data }) => {
        localStorage.setItem("token", JSON.stringify(data.data.access_token)); // token
        localStorage.setItem("userData", JSON.stringify(data.data.user)); // user data
        const { limited_access } = data.data.user;
        if (limited_access) {
          window.location.replace("/dashboard/startups");
        } else {
          window.location.replace("/dashboard");
        }
        setisLoading(false);
      })
      .catch((err) => {
        toast({
          title: `Incorrect email/password`,
          status: "error",
          isClosable: true,
        });
        setisLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <AddTitleHelmet title="Syndicate Investors | Sign in" />
      <Box>
        <Text color="#2A2A2A" fontSize={20} fontWeight={400} mb="36px">
          Sign in to continue
        </Text>
        <form
        >
          <Stack spacing="21px" marginBottom="29px">
            <TextInput
              label="Email Address"
              placeholder="example@email.com"
              handleInput={(payload) => setemail(payload)}
            />
            <PasswordInput
              label="Password"
              handleInput={(payload) => setpassword(payload)}
            />
          </Stack>

          <ForgotPassword />
          <PrimaryButton
            onClick={(e) => {
              e.preventDefault();
              handleSubmission();
            }}
            disabled={!email || !password || isLoading}
            label="Sign In"
          />
        </form>
      </Box>
      {/* reset password */}
      <ResetPassword show={showResetPassword} />
    </>
  );
};

export default SignInPage;
