import React from "react";
import {
  Flex,
  Box,
  Text,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  useToast,
} from "@chakra-ui/react";
import { resetPassword } from "../utils/calls/auth.calls";
import { PasswordInput } from "../components/Inputs";
import { PrimaryButton } from "../components/Buttons";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const ResetPassword = ({ show }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure(show);
  const [isLoading, setisLoading] = React.useState(false);
  const [newPassword, setnewPassword] = React.useState("");
  const [newPasswordConfirm, setnewPasswordConfirm] = React.useState("");
  const location = useLocation();
  const parsedQuery = queryString.parse(location.search);

  const handleResetPassword = () => {
    setisLoading(true);
    resetPassword(parsedQuery.token, newPassword, newPasswordConfirm)
      .then((response) => {
        let { data } = response;
        setisLoading(false);
        onClose();
        toast({
          title: `${data.data.message}`,
          status: "success",
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "User does not exist",
          status: "error",
          isClosable: true,
        });
        setisLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={show}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent
          borderRadius={0}
          p={["34px 5% 100px", "34px 5% 100px", "51px 105px", "51px 105px"]}
          maxWidth={["100%", "90%", "658px", "658px"]}
          m={["auto 0 0", "initial"]}
        >
          <Text
            textAlign={["left", "center"]}
            color="#4E5457"
            fontSize={[22, 32]}
            fontWeight={400}
            lineHeight="32px"
            mb={["25px", "30px"]}
          >
            Password Reset
          </Text>
          <Text
            textAlign={["left", "center"]}
            color="#4E5457"
            fontSize={16}
            fontWeight={400}
            mb={["25px", "30px"]}
          >
            Enter your new password for Syndicate account
          </Text>
          <form
          // onSubmit={(e) => {
          //   e.preventDefault();
          //   handleResetPassword();
          // }}
          >
            <Stack
              spacing="21px"
              marginBottom="29px"
              maxW="340px"
              marginX="auto"
            >
              <PasswordInput
                label="New password"
                handleInput={(payload) => setnewPassword(payload)}
              />
              <PasswordInput
                label="Confirm new password"
                handleInput={(payload) => setnewPasswordConfirm(payload)}
              />
              <PrimaryButton
                onClick={(e) => {
                  e.preventDefault();
                  handleResetPassword();
                }}
                disabled={!newPassword || !newPasswordConfirm || isLoading}
                label="Reset Password"
              />
            </Stack>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ResetPassword;
