import React from "react";
import { Box, Text, VStack, HStack } from "@chakra-ui/react";
import { AccordionDownIcon, AccordionUpIcon, BackIcon } from "../Icons";
import numeral from "numeral";
import moment from "moment";
import StatusComponent from "./statusComponent";
import PaymentItemContent from "../PaymentItemContent";

export const PaymentCardMobile = ({
  payment,
  showPaymentDetailMobile,
  clickable,
}) => {
  return (
    <VStack
      spacing={8}
      width="100%"
      rounded="2px"
      border="0.5px solid #858585"
      padding={3}
      display={["block", "block", "block", "none"]}
      onClick={() => {
        clickable && showPaymentDetailMobile();
      }}
    >
      <HStack justifyContent="space-between">
        <Text fontSize="12px">
          {moment(payment.created_at).format("DD-MMM-YYYY")}
        </Text>
        <StatusComponent status={payment.payment_tag} />
      </HStack>

      <HStack fontWeight="500" fontSize={18} justifyContent="space-between">
        <Text>{payment.name}</Text>
        <Text> {numeral(payment.amount).format("$0,0")}</Text>
      </HStack>
    </VStack>
  );
};

export const PaymentListMobile = ({ payments }) => {
  const [show, setshow] = React.useState(false);
  const [selectedPayment, setselectedPayment] = React.useState({});

  return (
    <>
      {/* start detail */}
      <Box display={["block", "block", "block", "none"]}>
        <Box
          display={show ? "block" : "none"}
          background="white"
          height="100vh"
          width="100%"
          position="fixed"
          top={0}
          left={0}
          zIndex={1}
          paddingTop="73px"
        >
          <HStack
            background="#FAFBFD"
            padding="20px 20px"
            onClick={() => setshow(false)}
            cursor="pointer"
          >
            <BackIcon />
            <Text fontWeight="600" fontSize="14px">
              Back to My Portfolio
            </Text>
          </HStack>
          <Box height="100%" overflowY="scroll" paddingY={8} paddingX="20px">
            <PaymentCardMobile payment={selectedPayment} clickable={false} />
            <PaymentItemContent {...selectedPayment} />
          </Box>
        </Box>

        {/* end detail */}
        <VStack
          width="100%"
          alignItems="flex-start"
          spacing={4}
          marginBottom="100px"
        >
          {payments.map((payment) => (
            <PaymentCardMobile
              clickable={true}
              key={payment.id}
              payment={payment}
              showPaymentDetailMobile={() => {
                setshow(true);
                setselectedPayment(payment);
              }}
            />
          ))}
        </VStack>
      </Box>
    </>
  );
};
